import ShuffleText from 'shuffle-text';

const ANIMATION_DURATION = 1000

type animationAndTrigger = {
  trigger: number,
  animation: ShuffleText
}
let animationAndTriggers: animationAndTrigger[] = []

const setUpShufflleTextAnimation = (el:Element) => {
  const animation = new ShuffleText(el as HTMLElement)
    animation.duration = ANIMATION_DURATION
    const rect = el.getBoundingClientRect()
    animationAndTriggers.push({
      trigger: rect.y - 50,
      animation
    })
}

window.addEventListener('load', () => {
  document.querySelectorAll('.dataTable__textAnimation').forEach((el) => setUpShufflleTextAnimation(el))
  document.querySelectorAll('.contentItem__heading').forEach((el) => setUpShufflleTextAnimation(el))
})

window.addEventListener('scroll', () => {
  animationAndTriggers.forEach((item) => {
    if (window.scrollY >= item.trigger - window.innerHeight) {
      if (!item.animation.isRunning) item.animation.start()
      setTimeout(() => {
        if (!item.animation.isRunning) item.animation.dispose()
      }, ANIMATION_DURATION + 200)
      animationAndTriggers.splice(animationAndTriggers.indexOf(item), 1)
    }
  })
})

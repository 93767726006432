import DOTS from 'vanta/dist/vanta.dots.min'
// import NET from 'vanta/dist/vanta.net.min'

window.addEventListener('load', () => {
  DOTS({
    el: "#heroView",
    mouseControls: true,
    touchControls: true,
    gyroControls: false,
    minHeight: 200.00,
    minWidth: 200.00,
    scale: 1.00,
    scaleMobile: 1.00,
    color: 0x821DBF,
    color2: 0xffffff,
    backgroundColor: 0xffffff,
    spacing: 15.00
  })
})

// window.addEventListener('load', () => {
//   NET({
//     el: "#heroView",
//     mouseControls: true,
//     touchControls: true,
//     gyroControls: false,
//     minHeight: 200.00,
//     minWidth: 200.00,
//     scale: 1.00,
//     scaleMobile: 1.00,
//     color: 0xBC7AE4,
//     backgroundColor: 0xffffff,
//     points: 15.00,
//     spacing: 18.00,
//     maxDistance: 25.00,
//   })
// })

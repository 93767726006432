let activeAvater: number = 0

const avaterAImg = document.getElementById('avaterA')
const avaterBImg = document.getElementById('avaterB')
const changeAvaterAIcon = document.getElementById('changeAvaterAIcon')
const changeAvaterBIcon = document.getElementById('changeAvaterBIcon')

const updateShowAvater = () => {
  avaterAImg.classList.toggle('--active')
  avaterBImg.classList.toggle('--active')
  changeAvaterAIcon.classList.toggle('--active')
  changeAvaterBIcon.classList.toggle('--active')
}

[changeAvaterAIcon, changeAvaterBIcon].forEach((element, i) => {
  element.addEventListener('click', () => {
    if (i !== activeAvater) {
      updateShowAvater()
      activeAvater = i
    }
  })
})

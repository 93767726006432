let count = 0
const loadingElement = document.getElementById('loading')
const loadingPercentageElement = document.getElementById('loadingPercentage')

const images = Array.from(document.getElementsByTagName('img'))
images.forEach((imageElement) => {
  const img = new Image()
  img.addEventListener('load', () => {
    count++
  })
  img.addEventListener('error', () => {
    count++
  })
  img.src = imageElement.src
})

const loading = setInterval(() => {
  loadingPercentageElement.style.width = `${10 + count / images.length * 90}%`
  if(count === images.length) {
    clearInterval(loading)
    setTimeout(() => {
      loadingElement.classList.add('--loaded')
      loadingPercentageElement.classList.add('--loaded')
      setTimeout(() => {
        loadingElement.remove()
      }, 1000)
    }, 1000)
  }
}, 100)
